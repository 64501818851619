import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Auth/ChangePassword")),
  },
  // {
  //   exact: true,
  //   path: "/",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home")),
  // },
  {
    exact: true,
    guard: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/DashdoardHome")),
  },
  {
    exact: true,
    guard: true,
    path: "/bank-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/Bankmanag")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-bank",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/AddBank")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-upi",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/AddUpi")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/WalletManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subadmin-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/SubAdminManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sub-admin-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/SubAdminTable")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-subadmin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/AddSubAdmin")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-admin-bank",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/ViewBankDetail")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-admin-bank",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/EditBank")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-admin-upi",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/EditUpi")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/user-detail",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserDetail")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/kyc-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/KycManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/report-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReportManagemet/ReportTable")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-kyc",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KycManagement/ViewKycDetail")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/log-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Logmanagement/LogMain")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/skill-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SkillManagement/Skillmanagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CategoryManagement/Category")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/static-content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/ContentManagement/StaticContentManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/EditContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-content",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContentManagement/ViewContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/EditProfile")),
  },
  {
    exact: true,
    guard: true,
    path: "/notification",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/Notification")),
  },
  {
    exact: true,
    guard: true,
    path: "/organization-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/organizationManagement/organization")
    ),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
