import React, { useEffect, useContext } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    // minHeight: "calc(100vh - 415px)",
  },

  root: {
    backgroundColor: "#fff",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  root1: {
    backgroundColor: "#000",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  MainLayout: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
    overflowY: "auto",
  },
  contentContainer1: {
    height: "calc(100vh - 75px)",
    // height: "100%",
    position: "relative",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  console.log(history.location);
  const location = useLocation();
  return (
    <div
      className={classes.root}
      // style={
      //   window.location.pathname !== "/"
      //     ? {}
      //     : { display: "flex", justifyContent: "center", alignItems: "center" }
      // }
    >
      <Box className="orangeshadeBox1"></Box>
      <Box className="purpleBox1"></Box>
      <TopBar />
      {/* <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div> */}
      <div className={classes.contentContainer1}>
        <div className={classes.MainLayout}>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
