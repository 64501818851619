import React, { createContext, useState, useEffect } from "react";
import ApiConfig from "src/ApiConfig/ApiConfig";
import axios from "axios";
import { calculateTimeLeft, getWeb3Obj } from "src/utils";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { swichNetworkHandler, getContract } from "src/utils";
import { ACTIVE_NETWORK, RPC_URL, toeknAddress } from "src/constants";
import web3 from "web3";
import TokenABI from "src/abi/TokenABI.json";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData] = useState({});
  const [timeLeft, setTimeLeft] = useState();
  const [endTime, setEndtime] = useState();
  const [adminData, SetAdminData] = useState([]);
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const [openMetamask, setOpenMetamask] = React.useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [tokenBalance, settokenBalance] = useState("");

  const walletConnect = async () => {
    try {
      setMetaMaskLoading(true);
      activate(injected, undefined, true)
        .then(() => {
          window.sessionStorage.setItem("ConnectWallet", true);
          setMetaMaskLoading(false);
          setOpenMetamask(false);
        })
        .catch((error) => {
          if (error) {
            toast.warn(error.message);
            console.log("ERROR", error);
            setMetaMaskLoading(false);
            setOpenMetamask(false);
            const errorMSG = error.message; //+ ' Please install Metamask';
            activate(injected);
          }
        });
    } catch (error) {
      console.log("ERROR", error);
      setMetaMaskLoading(false);
    }
  };

  const getOurWalletBlc = async () => {
    const web3 = getWeb3Obj(RPC_URL);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const getTokenWalletBalance = async () => {
    const contractObjToken = getContract(
      toeknAddress,
      TokenABI,
      library,
      account
    );
    const getTokenBalance = await contractObjToken.balanceOf(account);
    const toStngBl = getTokenBalance.toString();
    const balancetoken = web3.utils.fromWei(toStngBl);
    settokenBalance(parseFloat(balancetoken).toFixed(3));
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("ConnectWallet")) {
      walletConnect();
    }
  }, [window.sessionStorage.getItem("ConnectWallet")]);

  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      toast.success("You have been disconnected successfully!");
      window.sessionStorage.removeItem("ConnectWallet");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (account && chainId) {
      getOurWalletBlc();
      getTokenWalletBalance();
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); // eslint-disable-line

  const adminProfile = async (token) => {
    try {
      const response = await axios({
        method: "GET",
        url: ApiConfig.viewAdminProfile,
        headers: {
          token: token,
        },
      });
      if (response?.data?.responseCode === 200) {
        SetAdminData(response?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let token = window?.sessionStorage?.getItem("token");
    if (token) {
      adminProfile(token);
    }
  }, [window?.sessionStorage?.getItem("token")]);

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    endTime,
    adminData,
    setEndtime,
    account,
    library,
    metaMaskLoading,
    openMetamask,
    setOpenMetamask,
    yourWalletBalance,
    tokenBalance,
    adminProfile: adminProfile,
    walletConnect: walletConnect,
    disconnectWalletHandler: disconnectWalletHandler,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
