export const NetworkContextName = "Avalanche testnet";
export const ACTIVE_NETWORK = 43113;
export const RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc"; //testnet
export const kycContractAddress = "0x8FE2c476A104FC24446CbDF6Dd8D20C8405a8df3";
export const multiSenderContractAddress =
  "0x0B79acdC8d32E0Be8A71D8A1c3733E8557949F70";
export const toeknAddress = "0x352E9437E5403fc580FE6dA999289D4696bF9b7a";
export const tokenValue = "10";
export const tokenName = "AVT";

export const NetworkDetails = [
  {
    chainId: "0xa869",
    chainName: "Avalanche testnet",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: [RPC_URL],
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
  },
];

/////////-------mainnet--------////////

// export const NetworkContextName = "Avalanche Mainnet";
// export const ACTIVE_NETWORK = 43114;
// export const RPC_URL = "https://api.avax.network/ext/bc/C/rpc"; //mainnet

// export const NetworkDetails = [
//   {
//     chainId: "0xa86a",
//     chainName: "Avalanche Mainnet",
//     nativeCurrency: {
//       name: "AVAX",
//       symbol: "AVAX",
//       decimals: 18,
//     },
//     rpcUrls: [RPC_URL],
//     blockExplorerUrls: ["https://snowtrace.io/"],
//   },
// ];
