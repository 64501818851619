// export const baseurl = "http://172.16.6.130:1974/"; // local
export const baseurl = "https://node-unodata.mobiloitte.io/"; // stagin
// export const baseurl = "https://node.kashdata.com/"; // master
// export const baseurl = "http://172.16.2.248:1974/"; //local URL
// export const baseurl = "http://172.16.1.217:1974/"; //local URL vishu
// export const baseurl = "http://172.16.1.217:1974/"; //local URL Moin

let user = `${baseurl}api/v1`;
let admin = `${user}/admin`;
let kyc = `${user}/kyc`;
let staticContent = `${user}/static`;
let skills = `${user}/skill`;
let report = `${user}/reportPost`;
let category = `${user}/category`;
let subadmin = `${user}/subAdmin`;
let organizationType = `${user}/organizationType`;

const ApiConfigs = {
  // admin Login

  emailForgotPassword: `${admin}/emailForgotPassword`,
  changePassword: `${admin}/changePassword`,
  login: `${admin}/login`,
  verifyOTPEmail: `${admin}/verifyOTPEmail`,
  resetPassword: `${admin}/resetPassword`,
  resendOTP: `${admin}/resendOTP`,
  changePassword: `${admin}/changePassword`,
  //admin
  dashboard: `${admin}/dashboard`,
  listUser: `${admin}/listUser`,
  deleteUser: `${admin}/deleteUser`,
  blockUnblockUser: `${admin}/blockUnblockUser`,
  viewAdminProfile: `${admin}/viewAdminProfile`,
  listBank: `${admin}/listBank`,
  addBank: `${admin}/addBank`,
  editBank: `${admin}/editBank`,
  deleteBank: `${admin}/deleteBank`,
  acitavateBank: `${admin}/acitavateBank`,
  editProfile: `${admin}/editProfile`,
  depositeList: `${admin}/depositeList`,
  approveDepositRequest: `${admin}/approveDepositRequest`,
  activityLogHistory: `${admin}/activityLogHistory`,

  //kyc
  listKyc: `${kyc}/listKyc`,
  approveKyc: `${kyc}/approveKyc`,
  rejectKyc: `${kyc}/rejectKyc`,

  //staticContent
  staticContentList: `${staticContent}/staticContentList`,
  editStaticContent: `${staticContent}/editStaticContent`,

  //skills
  listSkill: `${skills}/listSkill`,
  addAdminSkill: `${skills}/addSkill`,
  deleteSkill: `${skills}/deleteSkill`,

  //report
  reportList: `${report}/reportList`,
  deleteReport: `${report}/deleteReport`,
  blockPost: `${report}/blockPost`,

  //category
  listCategory: `${category}/listCategory`,
  addCategory: `${category}/addCategory`,
  editCategory: `${category}/editCategory`,
  deleteCategory: `${category}/deleteCategory`,

  //sub Admin
  addSubAdmin: `${subadmin}/addSubAdmin`,
  updateSubAdmin: `${subadmin}/updateSubAdmin`,

  //Organization
  listOrganizationType: `${organizationType}/listOrganizationType`,
  addOrganizationType: `${organizationType}/addOrganizationType`,
  updateOrganizationType: `${organizationType}/updateOrganizationType`,
};

export default ApiConfigs;
