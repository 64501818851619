import { Contract } from "@ethersproject/contracts";
import axios from "axios";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import Web3 from "web3";
import * as XLSX from "xlsx";

const CryptoJS = require("crypto-js");
// export const aesKey =
//   "U2FsdGVkX18s6c8VorV8aKWr6bq8B2V5kFAQxFJvnKb1nByRKfmHmTZQ3CAfmWl";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const getWeb3Obj = (RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress, RPC_URL) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / ((1000 / 60) * 60 * 24)),
        hours: Math.floor((difference / ((1000 / 60) * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const encryptData = (params) => {
  let data = params;
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    data?.aesKey
  ).toString();
  return ciphertext;
};

// export const validateAccountAddress = async (account) => {
//   const RPC_URL = "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
//   const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
//   const web3 = new Web3(httpProvider);
//   try {
//     const accountCheck = web3.utils.toChecksumAddress(account);
//     if (accountCheck !== "") {
//       return true;
//     }
//   } catch (error) {
//     console.log(error);
//     return false;
//   }
// };

export const swichNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      addNetworkHandler();
    }
  }
};
const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const permissionArray = [
  { perName: "Dashboard" },
  { perName: "User Management" },
  { perName: "Sub Admin Management" },
  { perName: "Wallet Management" },
  { perName: "KYC Management" },
  { perName: "Bank Management" },
  { perName: "Report Management" },
  { perName: "Content Management" },
  { perName: "Skills Management" },
  { perName: "Category Management" },
  { perName: "Logs Management" },
  { perName: "Organization Management" },
];

export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};

export const listUserHandlerExcel = async (
  pageT,
  totalPage,
  ApiType,
  ApiEndPoint,
  statusTab
) => {
  try {
    const status = statusTab
      ? { approveStatus: statusTab ? statusTab : "" }
      : ApiType !== undefined
      ? { userType1: ApiType ? ApiType : "" }
      : {};
    const res = await axios({
      method: "GET",
      url: ApiEndPoint,
      headers: {
        token: sessionStorage.getItem("token"),
      },
      params: {
        page: pageT,
        limit: totalPage,
        ...status,
      },
    });
    if (res.data.responseCode === 200) {
      return res.data.result.docs;
    }
  } catch (error) {
    console.log(error);
  }
};
